<script>
import PageHeader from "@/components/page-header";
import { ApiClientSelectedPlatform } from "@/common/http-common"
import {  BredcrumpManagerDecode } from "@/common/bredcrumb-common"
import { networkRequesErrorResponseLogic } from "@/common/http-common-response-error"
import { networkRequesListResponseLogic } from "@/common/http-common-response-list"
export default {
  components: {
    PageHeader
  },
  data() {
    return {
      title: this.$t('form.agent.titles.agent'),
      items: [],
      pagination: {
        total: 0,
        totalPages: 0,
        page: 1,
        pageSize: 10,
        nextPages: [],
        backPages: []
      }

    };
  },
  watch: {
    $route (to,from){
      if (to.name == "agent" && from.name == "platform/agent")
      window.location.reload(true);
    }
  },
  methods: {
    getData: function (page, size) {
      const self = this;
      document.getElementById("preloader").style.display = "block";
      document.getElementById("status").style.display = "block";
      var rs = JSON.parse(atob(this.$route.query.data))
      ApiClientSelectedPlatform(rs.platformId).get("/v1/admin/employees/list?page=" + page + "&size=" + size).then(function (response) {
        networkRequesListResponseLogic(self, response, page, size, "form.agent.titles.agent")
      }).catch(
        function (error) {
          networkRequesErrorResponseLogic(self, error)
        }
      )
    },
    configureDataNextModule:function(id){
      if (this.$route.query.data){
        var rs = JSON.parse(atob(this.$route.query.data))
        rs.itemId = id
        return btoa(JSON.stringify(rs))
      }   
    },
    configureLinkNextModule:function(url){
        if (this.$route.path.includes("platform/detail/")){
          return "/platform/detail/"+url
        }else {
          return url
        }
    }
  },
  mounted() {
    if (this.$route.query.data) {
      var data = BredcrumpManagerDecode(this)
      if (data) {
        this.pagination.page = parseInt(data.page)
        this.pagination.pageSize = parseInt(data.size)
        this.getData(this.pagination.page, this.pagination.pageSize)
      } else {
        this.getData(this.pagination.page, this.pagination.pageSize)
      }
    } else {
      this.getData(this.pagination.page, this.pagination.pageSize)
    }
  }
};
</script>
<template>
     <div class="row">
        <div class="col-xl-12" style="padding: 0!important;">
        <PageHeader :title="title" />
      </div>
        <div class="col-xl-12">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">{{ this.$t('form.agent.titles.agent') }}</h4>
              <p class="card-title-desc">
                <router-link
                              :to="{ path: configureLinkNextModule('agent/create'), query: { data: configureDataNextModule(null)} }">{{ $t("form.agent.titles.create") }}</router-link>
              </p>
              <div class="table-responsive">
                <table class="table mb-0">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>{{ $t("form.agent.labels.agent") }}</th>
                      <th>{{ $t("form.agent.labels.configuration")}}</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in items" :key="index">
                      <td>{{ item.id }}</td>
                      <td v-if="item.user">
                        {{ item.user.first_name }} {{ item.user.last_name }} <hr style="margin-top: 0.5rem!important;margin-bottom: 0.5rem!important;">
                        {{ $t("form.agent.labels.position") }} <div>{{ item.detail.permission.title }}</div><hr style="margin-top: 0.5rem!important;margin-bottom: 0.5rem!important;">
                        {{ $t("form.agent.labels.email") }}<div>{{ item.email }} </div>
                        <hr style="margin-top: 0.5rem!important;margin-bottom: 0.5rem!important;">
                        {{ $t("form.agent.labels.phone") }}<div v-if="item.user.phone_one">{{ item.user.phone_one }}</div>
                        <div v-else>{{ $t("no.value") }}</div>
                        <hr style="margin-top: 0.5rem!important;margin-bottom: 0.5rem!important;">
                        {{ $t("form.agent.labels.phone2") }}<div v-if="item.user.phone_two">{{ item.user.phone_two }}</div>
                        <div v-else>{{ $t("no.value") }}</div><hr style="margin-top: 0.5rem!important;margin-bottom: 0.5rem!important;">

                        {{ $t("form.agent.labels.platform") }} <div>{{ item.detail.domain.title }}</div><hr style="margin-top: 0.5rem!important;margin-bottom: 0.5rem!important;">
                        {{ $t("form.agent.labels.status") }} <div>{{ $t("constants." + item.detail.status) }}</div>
                      </td>
                      <td v-else>
                        {{ $t("form.agent.labels.position") }} <div>{{ item.detail.permission.title }}</div><hr style="margin-top: 0.5rem!important;margin-bottom: 0.5rem!important;">
                        {{ $t("form.agent.labels.email") }}<div>{{ item.email }} </div>
                        {{ $t("form.agent.labels.platform") }} <div>{{ item.detail.domain.title }}</div><hr style="margin-top: 0.5rem!important;margin-bottom: 0.5rem!important;">
                        {{ $t("form.agent.labels.status") }} <div>{{ $t("constants." + item.detail.status) }}</div>
                        </td>
                      <td>
                        
                        <div v-if="item.detail.leader">
                          <div>{{ $t("form.agent.labels.leader") }} <b>({{ item.detail.leader.detail.permission.title }})</b> 
                            <span v-if=" item.detail.leader.user">{{ item.detail.leader.user.first_name }} {{ item.detail.leader.user.last_name }}</span>
                            <span v-else>{{ item.email }}</span>
                          </div>
                          {{ $t("form.agent.labels.leaderPercentage") }} <b>{{ item.detail.leaderEarnings }}%</b>
                        </div>
                        <div v-else>{{ $t("form.agent.labels.leader") }} <b>{{ $t("constants.NONE") }}</b></div>
                        <hr style="margin-top: 0.5rem!important;margin-bottom: 0.5rem!important;">
                        <div v-if="item.detail.owner">
                          <div>{{ $t("form.agent.labels.owner") }} <b>({{ item.detail.owner.detail.permission.title }})</b> 
                            <span v-if="item.detail.owner.user">{{ item.detail.owner.user.first_name }} {{ item.detail.owner.user.last_name }}</span>
                            <span v-else>{{ item.email }}</span>
                          </div>
                          {{ $t("form.agent.labels.ownerPercentage") }} <b> {{ item.detail.ownerEarnings }}%</b>
                        </div>
                        <div v-else> {{ $t("form.agent.labels.owner") }} <b>{{ $t("constants.NONE") }}</b></div>
                        <hr style="margin-top: 0.5rem!important;margin-bottom: 0.5rem!important;">
                        <div v-if="item.detail.order_types.length != 0">
                          {{ $t("form.agent.labels.orderTypes") }}
                          <div v-for="(order_type,order_typeIndex) in item.detail.order_types" :key="order_typeIndex">{{ order_type.title }} - <b>{{
                          order_type.value }} %</b></div>
                        </div>
                        <div v-else>{{ $t("form.agent.labels.orderTypes") }} <b>{{ $t("constants.NONE")}}</b></div>
                      </td>
                      <td>
                        <router-link
                              :to="{ path:'agent/update', query: { data: configureDataNextModule(item.id)} }">{{ $t("form.agent.titles.update") }}</router-link></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="row mt-4" v-if="pagination.totalPages != 1 && items.length != 0">
                <div class="col-sm-6">
                  <div>
                    <p class="mb-sm-0 mt-2">
                      {{ this.$t("pagination.page") }}
                      <span class="font-weight-bold">{{ pagination.page }}</span> {{ this.$t("pagination.from") }}
                      <span class="font-weight-bold">{{ pagination.totalPages }}</span>
                    </p>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="float-sm-right">
                    <ul class="pagination pagination-rounded mb-sm-0">
                      <li class="page-item" v-bind:class="(pagination.page == 1) ? 'disabled' : ''">
                        <a href="#" class="page-link" @click="getData(parseInt(pagination.page) - 1, pagination.pageSize)">
                          <i class="mdi mdi-chevron-left"></i>
                        </a>
                      </li>
                      <li class="page-item" v-for="item in pagination.backPages" :key="item">
                        <a href="#" class="page-link" @click="getData(item, pagination.pageSize)">{{ item }}</a>
                      </li>
                      <li class="page-item active">
                        <a href="#" class="page-link">{{ pagination.page }}</a>
                      </li>
                      <li class="page-item" v-for="item in pagination.nextPages" :key="item">
                        <a href="#" class="page-link" @click="getData(item, pagination.pageSize)">{{item}}</a>
                    </li>
                    <li class="page-item" v-bind:class="(pagination.page == pagination.totalPages) ? 'disabled' :'' ">
                      <a href="#" class="page-link" @click="getData(parseIn(pagination.page)+1, pagination.pageSize)">
                        <i class="mdi mdi-chevron-right"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>